<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
  <path
   fill-rule="evenodd"
   clip-rule="evenodd"
   d="M6.52358 13.6687C7.15671 13.6891 7.72857 14.5265 9.40329 14.5265C12.5485 14.5469 14.7134 12.1369 15.3874 8.9713C17.7361 -1.99613 1.74447 -2.87434 0.110593 6.15285C-0.277454 8.2769 0.355676 10.7277 2.00998 11.5242C3.27624 12.1369 3.35793 10.3805 2.96989 9.62485C1.25431 6.27539 3.31709 3.25271 6.01299 2.39493C8.54551 1.57798 10.3428 2.25196 11.7112 3.66118C13.4676 5.47888 12.6711 10.4214 10.5879 11.994C9.85261 12.5454 8.50466 12.668 7.81026 12.0144C6.38061 10.6869 8.58635 7.86843 8.25958 5.80565C7.9328 3.7633 4.70589 4.06965 4.5425 7.07191C4.4608 8.60367 4.91012 9.05299 4.56292 10.5848C4.01149 13.0356 2.33676 18.0393 3.52132 20C5.25732 19.1831 6.11511 14.2814 6.52358 13.6687Z"
   fill="black"
  />
 </svg>
</template>
